import { inject } from "vue";

export default () => {
  const moment = inject("$moment");
  const convertDateFormat = (date) => {
    return moment(date).format("LL");
  };
  const convertDateTimeFormat = (date) => {
    return moment(date).format("LLL");
  };
  const convertAmountFormat = (amount) => {
    let result = amount.toLocaleString("en-PH", {
      style: "currency",
      currency: "PHP",
    });
    return `${result}`;
  };
  return {
    convertDateFormat,
    convertDateTimeFormat,
    convertAmountFormat,
  };
};
