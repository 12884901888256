import { ref, computed } from "vue";

export default () => {
  const steps = ref([
    {
      step: 1,
      name: "Assessment",
    },
    {
      step: 2,
      name: "Document Evaluation",
    },
    {
      step: 3,
      name: "Confirmation",
    },
  ]);
  const currentStep = ref(1);

  const progressWidth = computed(() => {
    return ((currentStep.value - 1) / (steps.value.length - 1)) * 100;
  });

  const nextStep = () => {
    if (currentStep.value < steps.value.length) {
      currentStep.value++;
    }
  };

  const prevStep = () => {
    if (currentStep.value > 1) {
      currentStep.value--;
    }
  };
  return {
    steps,
    currentStep,
    progressWidth,
    nextStep,
    prevStep,
  };
};
