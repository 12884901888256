import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = process.env.VUE_APP_AUTH_TOKEN;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axios;
