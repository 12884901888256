<template>
  <svg
    width="85"
    height="85"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.6667 13.5V30.1667C41.6667 31.2717 41.2277 32.3315 40.4463 33.1129C39.6649 33.8943 38.6051 34.3333 37.5 34.3333H20.8333M37.5 59.3333L45.8333 67.6667L62.5 51M79.1667 17.6667V84.3333C79.1667 85.4384 78.7277 86.4982 77.9463 87.2796C77.1649 88.061 76.1051 88.5 75 88.5H25C23.8949 88.5 22.8351 88.061 22.0537 87.2796C21.2723 86.4982 20.8333 85.4384 20.8333 84.3333V33.975C20.8336 32.87 21.2727 31.8104 22.0542 31.0292L38.3625 14.7208C39.1437 13.9394 40.2033 13.5002 41.3083 13.5H75C76.1051 13.5 77.1649 13.939 77.9463 14.7204C78.7277 15.5018 79.1667 16.5616 79.1667 17.6667Z"
      stroke="#A6A6A6"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
