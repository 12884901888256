<template>
  <div class="login-container">
    <div>
      <h2>Login</h2>
      <form>
        <div class="form-group">
          <label for="username">Username</label>
          <input type="text" id="username" required />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" id="password" required />
        </div>
        <button type="submit" @click="goToApplication">Log In</button>
      </form>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();

    const goToApplication = () => {
      router.push({ name: "Dashboard" });
    };

    return {
      goToApplication,
    };
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 80px;
  background-color: var(--accent-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 460px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #666;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  font-size: 16px;
}

input[type="text"]:focus,
input[type="password"]:focus {
  border-color: #999;
  outline: none;
}

button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: white;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #e38947;
}

.form-group input:focus {
  border-color: var(--primary-color);
  outline: none;
}
</style>
