import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./utils/axios";
import moment from "moment";
import "./assets/css/global.css";
import HeroTopHeader from "@/components/common/HeroTopHeader";
import HeroLogo from "@/components/common/HeroLogo";
import HeroFooter from "@/components/common/HeroFooter.vue";
import HeroSideBar from "@/components/common/HeroSideBar.vue";
import HeroAvatar from "@/components/common/HeroAvatar.vue";
import HeroModal from "@/components/common/HeroModal.vue";

const app = createApp(App);

app.use(store);
app.use(router);
app.provide("$router", router);
app.provide("$axios", axios);
app.provide("$store", store);
app.provide("$moment", moment);
app.component("global-top-header", HeroTopHeader);
app.component("global-logo", HeroLogo);
app.component("global-footer", HeroFooter);
app.component("global-side-bar", HeroSideBar);
app.component("global-avatar", HeroAvatar);
app.component("global-modal", HeroModal);
app.mount("#app");
