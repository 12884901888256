<template>
  <div>
    <h1></h1>
    <SectionApplicationStep1
      :formData="formDataRef"
      @update-form="emitFormData"
      :isDisabled="true"
    />
  </div>
</template>

<script>
import { toRefs } from "vue";
import SectionApplicationStep1 from "@/components/partials/Account/Reimbursements/SectionApplicationStepOne.vue";

export default {
  components: {
    SectionApplicationStep1,
  },
  props: {
    formData: Object,
  },

  setup(props, { emit }) {
    const { formData: formDataRef } = toRefs(props);

    const emitFormData = (data) => {
      emit("update-form", data);
    };

    return {
      formDataRef,
      emitFormData,
    };
  },
};
</script>
