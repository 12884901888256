<template>
  <div class="action-button">
    <button @click="isActionResult = true">
      <AddIconSVG />
      Add New Item
    </button>
  </div>
  <main class="panel">
    <SectionReimbursement
      :actionButton="isActionResult"
      @actionHandler="getDataValue"
    />
  </main>
</template>
<script>
import AddIconSVG from "@/components/svg/AddIcon.vue";
import SectionReimbursement from "@/components/partials/Account/Reimbursements/SectionTransactions.vue";
import { ref } from "vue";
export default {
  components: {
    SectionReimbursement,
    AddIconSVG,
  },
  setup() {
    const isActionResult = ref(false);
    const getDataValue = (val) => {
      isActionResult.value = val;
    };

    return {
      isActionResult,
      getDataValue,
    };
  },
};
</script>

<style scoped>
.action-button {
  display: flex;
  justify-content: flex-end;
  position: relative;
  bottom: 45px;
}
button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  height: 40px;
  width: 140px;
  background-color: var(--primary-color);
  color: var(--light-color);
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  position: absolute;
}
</style>
