<template>
  <div class="container">
    <form action="">
      <input type="text" />
    </form>
  </div>
</template>
<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
form input {
  min-width: 815px;
  height: 40px;
  padding: 5px 10px;
}
</style>
