<template>
  <form class="modal-container">
    <div class="form-group">
      <div class="container">
        <label for="">Company Name <span class="required">*</span></label>
        <div class="input">
          <input
            v-model="localFormData.companyName"
            type="text"
            placeholder="Name of Agency / Company"
            :disabled="isDisabled"
          />
        </div>
      </div>
      <div class="container">
        <label for="">Member's Name <span class="required">*</span></label>
        <div class="input">
          <input
            v-model="localFormData.memberName"
            type="text"
            placeholder="Dela Cruz, Juan"
            :disabled="isDisabled"
          />
        </div>
      </div>

      <div class="container">
        <label>Type of Availment <span class="required">*</span></label>
        <select
          v-model="localFormData.selectedAvailmentType"
          :disabled="isDisabled"
        >
          <option disabled value="">--Please select--</option>
          <option
            v-for="(item, index) in availmentItems"
            :key="index"
            :value="item"
          >
            {{ item }}
          </option>
        </select>
      </div>

      <div
        class="container"
        v-if="localFormData.selectedAvailmentType === 'Out-Patient'"
      >
        <label for="">Transaction Type <span class="required">*</span></label>
        <select
          v-model="localFormData.selectedTransType"
          id="options"
          :disabled="isDisabled"
        >
          <option disabled value="">--Please select--</option>
          <option
            v-for="(item, index) in transtypeItems"
            :key="index"
            :value="item"
          >
            {{ item }}
          </option>
        </select>
      </div>

      <div class="container">
        <label for="">Provider Name <span class="required">*</span></label>
        <div class="input">
          <input
            v-model="localFormData.providerName"
            type="text"
            :disabled="isDisabled"
          />
        </div>
      </div>

      <div class="container">
        <label for="">Date of Admission <span class="required">*</span></label>
        <div class="input">
          <input
            v-model="localFormData.admitdate"
            type="date"
            :disabled="isDisabled"
          />
        </div>
      </div>
      <div
        class="container"
        v-if="localFormData.selectedAvailmentType !== 'Out-Patient'"
      >
        <label for="">Date of Discharged <span class="required">*</span></label>
        <div class="input">
          <input
            v-model="localFormData.dischargedate"
            type="date"
            :disabled="isDisabled"
          />
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="container">
        <label for=""
          >{{
            localFormData.selectedAvailmentType === "In-Patient"
              ? "Final Diagnosis"
              : "Chief Complaint"
          }}
          <span class="required">*</span></label
        >
        <div class="input">
          <textarea
            v-model="localFormData.remarks"
            rows="2"
            :disabled="isDisabled"
          />
        </div>
      </div>

      <div class="container">
        <label for="">Contact Person <span class="required">*</span></label>
        <div class="input">
          <input
            v-model="localFormData.contactperson"
            type="text"
            placeholder="Name of Agent / Company Agent"
            :disabled="isDisabled"
          />
        </div>
      </div>

      <div class="container">
        <label for="">Approval Type <span class="required">*</span></label>
        <select
          id="options"
          v-model="localFormData.selectedApprovalType"
          :disabled="isDisabled"
        >
          <option disabled value="">--Please select--</option>
          <option
            v-for="(item, index) in approvaltypeItems"
            :key="index"
            :value="item"
          >
            {{ item }}
          </option>
        </select>
      </div>

      <div
        class="container"
        v-if="localFormData.selectedApprovalType === 'Declined'"
      >
        <label for="">Declined Reason <span class="required">*</span></label>
        <select
          v-model="localFormData.declinedreason"
          id="options"
          :disabled="isDisabled"
        ></select>
      </div>

      <div class="container">
        <label for="">Remarks <span class="required">*</span></label>
        <div class="input">
          <textarea
            v-model="localFormData.declinedremarks"
            rows="2"
            :disabled="isDisabled"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, watch, toRefs } from "vue";

export default {
  props: {
    formData: Object,
    isDisabled: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const { formData } = toRefs(props);
    const localFormData = ref({ ...formData.value });

    const emitFormData = () => {
      emit("update-form", localFormData.value);
    };

    watch(localFormData, emitFormData, { deep: true });

    const availmentItems = ref(["In-Patient", "Out-Patient"]);
    const transtypeItems = ref(["Consultation", "Laboratory"]);
    const approvaltypeItems = ref([
      "For Evaluation",
      "Pending",
      "Approved",
      "Declined",
    ]);

    return {
      localFormData,
      availmentItems,
      transtypeItems,
      approvaltypeItems,
    };
  },
};
</script>

<style scoped>
.modal-container {
  display: flex;
  gap: 20px;
}

.form-group {
  flex: 1;
}
.sub-item {
  font-size: 11px;
  font-weight: 300;
  font-style: italic;
}

.submit-btn {
  height: 40px;
  color: var(--accent-color);
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}
.form-group .container {
  display: flex;
  flex-direction: column;
}
.form-group .container label {
  text-align: left;
  font-size: 13px;
  margin: 8px 0px;
}
.form-group .container .sub-item {
  font-size: 11px;
}
</style>
