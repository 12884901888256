<template>
  <global-top-header />
  <global-logo />
  <SectionForm />

  <div class="footer">
    <global-footer />
  </div>
</template>
<script>
import SectionForm from "@/components/partials/Account/Reimbursements/SectionForm.vue";
export default {
  components: {
    SectionForm,
  },
  setup() {},
};
</script>
<style scoped>
section {
  margin: 10px 0px;
  max-width: 1600px;
  align-items: center;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}
</style>
