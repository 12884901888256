<template>
  <div class="reimbursement-status">
    <div class="status-grid">
      <div class="status-item received">
        <div class="status-icon received-bg">
          <ReceiveDocumentsIcon />
        </div>
      </div>
      <div class="status-connector"></div>
      <div class="status-item verification">
        <div class="status-icon verification-bg">
          <VerificationIcon />
        </div>
      </div>
      <div class="status-connector"></div>
      <div class="status-item approved">
        <div class="status-icon approved-bg">
          <ApprovedIcon />
        </div>
      </div>
      <div class="status-connector"></div>
      <div class="status-item payment">
        <div class="status-icon payment-bg">
          <PaymentReceive />
        </div>
      </div>
    </div>
    <div class="status-label">
      <div class="status-label-item">Receive Documents</div>
      <div class="status-label-item">
        Verification and Processing of Availment
      </div>
      <div class="status-label-item">Approved Reimbursement</div>
      <div class="status-label-item">Payment Received</div>
    </div>

    <h2>Reimbursement Details</h2>

    <div class="reimbursement-details">
      <table>
        <tbody>
          <tr>
            <td><strong>Member Name:</strong></td>
            <td>{{ itemData.name }}</td>
          </tr>
          <tr>
            <td><strong>Company:</strong></td>
            <td>{{ itemData.comp }}</td>
          </tr>
          <tr>
            <td><strong>Provider Name:</strong></td>
            <td>{{ itemData.prov }}</td>
          </tr>
          <tr>
            <td><strong>Availment Type:</strong></td>
            <td>{{ itemData.availType }}</td>
          </tr>
          <tr>
            <td><strong>Date of Admission:</strong></td>
            <td>{{ itemData.adDate }}</td>
          </tr>
          <tr>
            <td><strong>Date of Discharge:</strong></td>
            <td>10/01/2024</td>
          </tr>
          <tr>
            <td><strong>Diagnosis / Chief Complaint:</strong></td>
            <td>
              HYPERSENSITIVITY REACTION, ETIOLOGY UNKNOWN, R/O ELECTROLYTE
              IMBALANCE, UPPER RESPIRATORY TRACT INFECTION (URTI)
            </td>
          </tr>
          <tr>
            <td><strong>Contact Person:</strong></td>
            <td>PADILLA, JOSELITO</td>
          </tr>
          <tr>
            <td><strong>SOA Amount:</strong></td>
            <td>₱1,500.00</td>
          </tr>
          <tr>
            <td><strong>Reimbursable Amount:</strong></td>
            <td>{{ itemData.amount }}</td>
          </tr>
          <tr>
            <td><strong>Declined Reason:</strong></td>
            <td>STILL INCOMPLETE REQUIREMENTS DESPITE SEVERAL NOTICES</td>
          </tr>
          <tr>
            <td><strong>Added By:</strong></td>
            <td>Nette (10/01/2024 03:03 PM)</td>
          </tr>
          <tr>
            <td><strong>Processed By:</strong></td>
            <td>Tin (10/01/2024 05:03 PM)</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h2>Downloadable Documents</h2>

    <div class="downloadable-documents">
      <ul>
        <li v-for="document in documents" :key="document.id">
          <span class="document-name">{{ document.name }}</span>
          <a :href="document.link" class="download-link">
            <p>Download</p>
            <DownloadIcon />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ReceiveDocumentsIcon from "@/components/svg/ReceiveDocumentsIcon.vue";
import VerificationIcon from "@/components/svg/VerificationIcon.vue";
import ApprovedIcon from "@/components/svg/ApprovedIcon.vue";
import PaymentReceive from "@/components/svg/PaymentReceive.vue";
import DownloadIcon from "@/components/svg/DownloadIcon.vue";
import { ref } from "vue";
export default {
  components: {
    ReceiveDocumentsIcon,
    VerificationIcon,
    ApprovedIcon,
    PaymentReceive,
    DownloadIcon,
  },
  props: {
    itemData: Object,
  },
  setup() {
    const documents = ref([
      { id: 1, name: "Medical Certificate / Diagnosis", link: "#" },
      { id: 2, name: "Clinical Abstract", link: "#" },
      { id: 3, name: "SOA (PHIC Deduction)", link: "#" },
      { id: 4, name: "O.R. (Original Copy)", link: "#" },
      { id: 5, name: "Surgical Report", link: "#" },
      { id: 6, name: "Police Report", link: "#" },
      { id: 7, name: "Incident Report", link: "#" },
    ]);

    return {
      documents,
    };
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 50px;
  width: 850px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1001;
  border-radius: 8px;
  overflow-y: auto;
  max-height: 80vh;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

h2 {
  margin-top: 0;
  text-align: left;
}

.reimbursement-status {
  margin-bottom: 20px;
}

.status-grid {
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 10px;
  margin-top: 20px;
}

.status-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 100px;
}

.status-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 8px;
}

.status-connector {
  height: 1px;
  background-color: #a6a6a6;
  width: 100%;
}

.status-label {
  display: flex;
  align-items: center;
  justify-items: center;
  margin: 20px 0 50px 0;
  gap: 170px;
}

.status-label-item {
  font-size: 13px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: center;
  font-weight: 600;
}

.reimbursement-details {
  padding: 20px;
  color: var(--text-semi-dark);
  margin: 20px 0 20px 0;
}

table {
  width: 100%;
  border-collapse: collapse;
}

td {
  border: 1px solid #ddd;
  padding: 8px;
}

td strong {
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

.downloadable-documents {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

.downloadable-documents h2 {
  text-align: center;
  margin-bottom: 20px;
}

.downloadable-documents ul {
  list-style: none;
  padding: 0;
}

.downloadable-documents li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.downloadable-documents .document-name {
  flex: 1;
  font-weight: bold;
  color: var(--text-semi-dark);
}

.downloadable-documents .download-link {
  font-size: 15px;
  text-decoration: none;
  color: #4caf50;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 3px;
}

.downloadable-documents .download-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.downloadable-documents .download-link p {
  padding-top: 9px;
}
</style>
