<template>
  <div class="container">
    <div class="avatar-image">
      <img
        src="https://img.freepik.com/premium-vector/female-user-profile-avatar-is-woman-character-screen-saver-with-emotions_505620-617.jpg?w=740"
        alt="icon-user"
      />
    </div>
    <div class="item-holder">
      <div class="item-username">Marie Nette Almoro</div>
      <div class="item-position">Reimbursement Officer</div>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  height: 130px;
  padding: 10px 10px 5px 10px;
  gap: 5px;
  margin-bottom: 10px;
  color: var(--text-semi-dark);
  justify-content: center;
}

.avatar-image img {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  max-width: 100%;
}

.avatar-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 5px;
  font-weight: 600;
}

.item-position {
  font-style: italic;
  font-weight: 400;
  font-size: 11px;
}

.item-username {
  font-size: 15px;
}
</style>
