<template>
  <div class="logo">
    <img :src="require('@/assets/logo.png')" alt="" />
  </div>
</template>
<style scoped>
.logo {
  margin: 40px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img {
  height: 10%;
  width: 10%;
}
</style>
