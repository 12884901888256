<template>
  <svg
    width="85"
    height="85"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M37.5 29.1667V9.25416C36.7308 9.64991 36.028 10.163 35.4167 10.775L19.1083 27.0833C18.4963 27.6946 17.9832 28.3975 17.5875 29.1667H37.5ZM45.8333 29.1667V8.33333H75C77.2101 8.33333 79.3297 9.2113 80.8925 10.7741C82.4554 12.3369 83.3333 14.4565 83.3333 16.6667V43.375C80.1902 40.5625 76.5103 38.4154 72.5152 37.063C68.5202 35.7106 64.2927 35.1809 60.0875 35.5059C55.8823 35.8308 51.7864 37.0037 48.0466 38.9537C44.3067 40.9038 41.0003 43.5908 38.3266 46.8527C35.6529 50.1147 33.6671 53.8842 32.489 57.934C31.3109 61.9839 30.9647 66.2303 31.4715 70.4175C31.9782 74.6047 33.3273 78.646 35.4375 82.2979C37.5477 85.9497 40.3753 89.1367 43.75 91.6667H25C22.7899 91.6667 20.6702 90.7887 19.1074 89.2259C17.5446 87.6631 16.6667 85.5435 16.6667 83.3333V37.5H37.5C39.7101 37.5 41.8297 36.622 43.3926 35.0592C44.9554 33.4964 45.8333 31.3768 45.8333 29.1667Z"
      fill="#EF9A5C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M37.5 68.6667C37.5 62.0362 40.1339 55.6774 44.8223 50.989C49.5107 46.3006 55.8696 43.6667 62.5 43.6667C69.1304 43.6667 75.4893 46.3006 80.1777 50.989C84.8661 55.6774 87.5 62.0362 87.5 68.6667C87.5 75.2971 84.8661 81.6559 80.1777 86.3443C75.4893 91.0327 69.1304 93.6667 62.5 93.6667C55.8696 93.6667 49.5107 91.0327 44.8223 86.3443C40.1339 81.6559 37.5 75.2971 37.5 68.6667ZM62.5 56.1667C63.6051 56.1667 64.6649 56.6056 65.4463 57.387C66.2277 58.1684 66.6667 59.2283 66.6667 60.3333V64.5H70.8333C71.9384 64.5 72.9982 64.939 73.7796 65.7204C74.561 66.5018 75 67.5616 75 68.6667C75 69.7717 74.561 70.8315 73.7796 71.6129C72.9982 72.3943 71.9384 72.8333 70.8333 72.8333H66.6667V77C66.6667 78.1051 66.2277 79.1649 65.4463 79.9463C64.6649 80.7277 63.6051 81.1667 62.5 81.1667C61.3949 81.1667 60.3351 80.7277 59.5537 79.9463C58.7723 79.1649 58.3333 78.1051 58.3333 77V72.8333H54.1667C53.0616 72.8333 52.0018 72.3943 51.2204 71.6129C50.439 70.8315 50 69.7717 50 68.6667C50 67.5616 50.439 66.5018 51.2204 65.7204C52.0018 64.939 53.0616 64.5 54.1667 64.5H58.3333V60.3333C58.3333 59.2283 58.7723 58.1684 59.5537 57.387C60.3351 56.6056 61.3949 56.1667 62.5 56.1667Z"
      fill="#EF9A5C"
    />
  </svg>
</template>
