<template>
  <div class="tracking-number">
    <h5>Transaction Number: IWC-X97BCU9ZC</h5>
  </div>
  <div class="progress-container">
    <div class="progress-bar" :style="{ width: progressWidth + '%' }"></div>
    <div class="progress-background"></div>
    <div
      class="step-tracking"
      v-for="(step, index) in steps"
      :key="index"
      :class="['step', { active: index < currentStep }]"
    >
      <span class="series"> {{ index + 1 }}</span>
      <span class="title"> {{ step.name }}</span>
    </div>
  </div>
</template>

<script>
import UsedTrackingUtils from "@/utils/tracking";
export default {
  props: {
    currentStep: {
      Number,
    },
    progressWidth: {
      Number,
    },
  },
  setup() {
    const { steps } = UsedTrackingUtils();
    return { steps };
  },
};
</script>

<style scoped>
.tracking-number {
  display: flex;
  justify-content: flex-start;
}
.progress-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 40px auto 50px auto;
}

.progress-background {
  position: absolute;
  top: 50%;
  left: 0;
  height: 4px;
  width: 100%;
  background-color: var(--disable-color);
  z-index: 1;
}

.progress-bar {
  position: absolute;
  top: 50%;
  left: 0;
  height: 5px;
  background-color: var(--enable-color);
  transition: width 0.6s ease;
  z-index: 2; /* Above the background */
}

.step {
  position: relative;
  z-index: 3;
  width: 30px;
  height: 30px;
  background-color: var(--disable-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.4s ease;
}

.step:not(.active) {
  background-color: #ddd;
  color: #666;
}
.step.active {
  background-color: var(--enable-color);
  color: var(--light-color);
}
.step-tracking {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.title {
  font-size: 12px;
  width: 180px;
  padding-top: 20px;
  text-align: center;
}
.step.active > .title {
  color: var(--enable-color);
}
.series {
  position: relative;
  top: 28px;
  bottom: 0px;
  font-size: 12px;
}
</style>
