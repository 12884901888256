<template>
  <HeroTracking :currentStep="currentStep" :progressWidth="progressWidth" />
  <component
    :is="currentApplication"
    :currentStep="currentStep"
    :formData="formData"
    @update-form="updateFormData"
  ></component>
  <div class="action-button">
    <button @click="prevStep" :disabled="currentStep === 1">Previous</button>
    <button
      @click="nextStep"
      :disabled="currentStep === 3 || currentStep === steps.length"
    >
      Next
    </button>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import UsedTrackingUtils from "@/utils/tracking";
import HeroTracking from "@/components/common/HeroTracking.vue";
import SectionApplicationStep1 from "@/components/partials/Account/Reimbursements/SectionApplicationStepOne.vue";
import SectionApplicationStep2 from "@/components/partials/Account/Reimbursements/SectionApplicationStepTwo.vue";
import SectionApplicationStep3 from "@/components/partials/Account/Reimbursements/SectionApplicationStepThree.vue";

export default {
  components: {
    HeroTracking,
    SectionApplicationStep1,
    SectionApplicationStep2,
    SectionApplicationStep3,
  },
  setup() {
    const { steps, nextStep, prevStep, currentStep, progressWidth } =
      UsedTrackingUtils();

    const formData = ref({
      companyName: "",
      memberName: "",
      selectedAvailmentType: "",
      selectedTransType: "",
      providerName: "",
      admitdate: "",
      dischargedate: "",
      remarks: "",
      contactperson: "",
      selectedApprovalType: "",
      declinedreason: "",
      declinedremarks: "",
    });

    const updateFormData = (data) => {
      formData.value = { ...formData.value, ...data };
    };

    const currentApplication = computed(() => {
      return `SectionApplicationStep${currentStep.value}`;
    });

    return {
      currentApplication,
      progressWidth,
      currentStep,
      steps,
      nextStep,
      prevStep,
      formData,
      updateFormData,
    };
  },
};
</script>

<style scoped>
.action-button {
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.action-button button {
  font-size: 10px;
  font-weight: 600;
  height: 40px;
  width: 140px;
  background-color: var(--primary-color);
  color: var(--light-color);
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  margin-top: 10px;
}

.action-button button:disabled {
  background-color: grey;
  cursor: not-allowed;
}
</style>
