<template>
  <global-top-header />
  <div class="main">
    <global-side-bar />
    <section>
      <HeroHeaderMenu @actionHandler="getDataValue" />
      <div class="container">
        <router-view />
      </div>
    </section>
  </div>
</template>
<script>
import HeroHeaderMenu from "@/components/common/HeroHeaderMenu.vue";
export default {
  components: {
    HeroHeaderMenu,
  },
  setup() {},
};
</script>
<style scoped>
.main {
  display: flex;
  gap: 24px;
  margin: 30px 40px;
}
section {
  width: 100%;
}
.container {
  margin: 16px 0px;
}
</style>
