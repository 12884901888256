<template>
  <div class="side-bar">
    <global-avatar />
    <div v-for="button in buttons" :key="button.id">
      <router-link class="side-bar-tab" :to="button.route">
        <component :is="button.icon" class="icon" />
        {{ button.name }}
      </router-link>
    </div>
  </div>
</template>

<script>
import IconDashboard from "@/components/svg/DashboardIcon.vue";
import IconReimbursement from "@/components/svg/ReimbursementIcon.vue";
import IconPayment from "@/components/svg/PaymentIcon.vue";
import { ref } from "vue";

export default {
  setup() {
    const buttons = ref([
      {
        id: 1,
        name: "Dashboard",
        icon: IconDashboard,
        route: "/admin/dashboard",
      },
      {
        id: 2,
        name: "Reimbursement",
        icon: IconReimbursement,
        route: "/admin/reimbursement",
      },
      { id: 3, name: "Payment", icon: IconPayment, route: "/admin/payment" },
    ]);
    return {
      buttons,
    };
  },
};
</script>

<style scoped>
.side-bar {
  font-size: 13px;
  width: 375px;
  height: 490px;
  background-color: var(--light-color);
  display: inline-block;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.side-bar-tab {
  color: var(--text-semi-dark);
  font-weight: 600;
  height: 55px;
  background-color: var(--bg-main-color-web);
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 12px;
  border: 0.5px solid#e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px 8px 0 8px;
  border-radius: 10px;
  padding: 0px 10px;
}

.side-bar-tab:hover {
  background-color: var(--primary-color);
  color: var(--accent-color);
}
</style>
