<template>
  <div class="upload-form">
    <form>
      <div
        v-for="document in documents"
        :key="document.name"
        class="form-group"
      >
        <label :for="document.name">{{ document.name }}</label>
        <input type="file" :id="document.name" @change="handleFileUpload" />
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const documents = ref([
      { name: "Medical Certificate / Diagnosis" },
      { name: "Clinical Abstract" },
      { name: "SOA (PHIC Deduction)" },
      { name: "O.R. (Original Copy)" },
      { name: "Surgical Report" },
      { name: "Police Report" },
      { name: "Incident Report" },
    ]);
    const files = ref({});

    const handleFileUpload = (event) => {
      const { id, files: selectedFiles } = event.target;
      files.value[id] = selectedFiles[0];
    };

    return {
      documents,

      files,
      handleFileUpload,
    };
  },
};
</script>

<style scoped>
.upload-form {
  padding: 20px;
  background: #fff;
  margin: auto;
}

.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  margin-right: 15px;
  flex: 1;
}

input[type="file"] {
  flex: 2;
}
</style>
