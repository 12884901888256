<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.1667 13.0083V4.66667C15.1667 4.35725 15.0438 4.0605 14.825 3.84171C14.6062 3.62292 14.3095 3.5 14 3.5C13.6906 3.5 13.3939 3.62292 13.1751 3.84171C12.9563 4.0605 12.8334 4.35725 12.8334 4.66667V13.0083L10.2434 9.76967C10.1495 9.64472 10.0315 9.53991 9.89634 9.46144C9.76119 9.38298 9.61166 9.33247 9.45661 9.31291C9.30156 9.29335 9.14417 9.30514 8.99377 9.34758C8.84336 9.39002 8.70302 9.46224 8.58106 9.55996C8.45911 9.65768 8.35803 9.7789 8.28383 9.91644C8.20962 10.054 8.16381 10.205 8.1491 10.3606C8.13439 10.5162 8.15109 10.6731 8.1982 10.8221C8.24532 10.9711 8.32189 11.1092 8.42337 11.228L13.09 17.0613C13.1994 17.1976 13.3379 17.3076 13.4954 17.3831C13.6529 17.4587 13.8253 17.4979 14 17.4979C14.1747 17.4979 14.3472 17.4587 14.5047 17.3831C14.6622 17.3076 14.8007 17.1976 14.91 17.0613L19.5767 11.228C19.6782 11.1092 19.7548 10.9711 19.8019 10.8221C19.849 10.6731 19.8657 10.5162 19.851 10.3606C19.8363 10.205 19.7904 10.054 19.7162 9.91644C19.642 9.7789 19.541 9.65768 19.419 9.55996C19.297 9.46224 19.1567 9.39002 19.0063 9.34758C18.8559 9.30514 18.6985 9.29335 18.5435 9.31291C18.3884 9.33247 18.2389 9.38298 18.1037 9.46144C17.9686 9.53991 17.8506 9.64472 17.7567 9.76967L15.1667 13.0083Z"
      fill="#0E9735"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.2665 18.5195L8.58433 15.1665H5.83333C5.21449 15.1665 4.621 15.4123 4.18342 15.8499C3.74583 16.2875 3.5 16.881 3.5 17.4998V22.1665C3.5 22.7853 3.74583 23.3788 4.18342 23.8164C4.621 24.254 5.21449 24.4998 5.83333 24.4998H22.1667C22.7855 24.4998 23.379 24.254 23.8166 23.8164C24.2542 23.3788 24.5 22.7853 24.5 22.1665V17.4998C24.5 16.881 24.2542 16.2875 23.8166 15.8499C23.379 15.4123 22.7855 15.1665 22.1667 15.1665H19.4157L16.7323 18.5195C16.4044 18.9293 15.9886 19.2602 15.5155 19.4875C15.0424 19.7149 14.5243 19.8329 13.9994 19.8329C13.4746 19.8329 12.9564 19.7149 12.4833 19.4875C12.0103 19.2602 11.5944 18.9293 11.2665 18.5195ZM19.8333 18.6665C19.5239 18.6665 19.2272 18.7894 19.0084 19.0082C18.7896 19.227 18.6667 19.5238 18.6667 19.8332C18.6667 20.1426 18.7896 20.4393 19.0084 20.6581C19.2272 20.8769 19.5239 20.9998 19.8333 20.9998H19.845C20.1544 20.9998 20.4512 20.8769 20.67 20.6581C20.8887 20.4393 21.0117 20.1426 21.0117 19.8332C21.0117 19.5238 20.8887 19.227 20.67 19.0082C20.4512 18.7894 20.1544 18.6665 19.845 18.6665H19.8333Z"
      fill="#0E9735"
    />
  </svg>
</template>
