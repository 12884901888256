<template>
  <svg
    width="85"
    height="85"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.5 84.3333H20.8333C19.7282 84.3333 18.6684 83.8944 17.887 83.113C17.1056 82.3315 16.6666 81.2717 16.6666 80.1667V26C16.6666 24.8949 17.1056 23.8351 17.887 23.0537C18.6684 22.2723 19.7282 21.8333 20.8333 21.8333H30.9541M29.1666 34.3333H41.6666M33.3333 34.3333V17.6667H50V26M66.6666 26V21.8333H50M62.5 38.5V51C62.5 52.1051 62.061 53.1649 61.2796 53.9463C60.4982 54.7277 59.4384 55.1667 58.3333 55.1667H45.8333M83.3333 42.6667V80.1667C83.3333 81.2717 82.8943 82.3315 82.1129 83.113C81.3315 83.8944 80.2717 84.3333 79.1666 84.3333H50C48.8949 84.3333 47.8351 83.8944 47.0537 83.113C46.2723 82.3315 45.8333 81.2717 45.8333 80.1667V53.5125C45.8338 52.4555 46.2359 51.4383 46.9583 50.6667L57.1 39.8208C57.4895 39.4042 57.9605 39.072 58.4837 38.8448C59.0069 38.6176 59.5712 38.5002 60.1416 38.5H79.1666C80.2717 38.5 81.3315 38.939 82.1129 39.7204C82.8943 40.5018 83.3333 41.5616 83.3333 42.6667Z"
      stroke="#A6A6A6"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
