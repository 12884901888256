import { createRouter, createWebHashHistory } from "vue-router";
import UsedAuthorizationIndex from "@/pages/Account/AuthorizationIndex.vue";
import UsedDashboardIndex from "@/pages/Account/DashboardIndex.vue";
import UsedReimbursementIndex from "@/pages/Account/ReimbursementIndex.vue";
import UsedTrackingIndex from "@/pages/General/TrackingIndex.vue";
import UsedPaymentIndex from "@/pages/Account/PaymentIndex.vue";

const routes = [
  {
    path: "/",
    name: "tracking",
    component: UsedTrackingIndex,
    meta: {
      title: "Tracking",
      layout: "General",
      requiresAuth: false,
    },
  },
  {
    path: "/admin/login",
    name: "login",
    component: UsedAuthorizationIndex,
    meta: {
      title: "Index",
      layout: "General",
      requiresAuth: false,
    },
  },
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    component: UsedDashboardIndex,
    meta: {
      title: "Dashboard",
      layout: "Account",
      requiresAuth: false,
    },
  },
  {
    path: "/admin/reimbursement",
    name: "Reimbursement",
    component: UsedReimbursementIndex,
    meta: {
      title: "Reimbursement",
      layout: "Account",
      requiresAuth: false,
    },
  },
  {
    path: "/admin/payment",
    name: "Payment",
    component: UsedPaymentIndex,
    meta: {
      title: "Payment",
      layout: "Account",
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
