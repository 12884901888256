<template>
  <component :is="RenderLayout"></component>
</template>
<script>
import GeneralLayout from "@/layout/GeneralLayout.vue";
import AccountLayout from "@/layout/AccountLayout.vue";
import { useRoute } from "vue-router";
import { computed } from "vue";
export default {
  components: {
    GeneralLayout,
    AccountLayout,
  },
  setup() {
    const route = useRoute();
    const RenderLayout = computed(() => {
      let routes = route.meta.layout;
      return `${routes}Layout`;
    });
    return {
      RenderLayout,
    };
  },
};
</script>
