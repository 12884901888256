<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12ZM13 7.757C13 7.49178 12.8946 7.23743 12.7071 7.04989C12.5196 6.86236 12.2652 6.757 12 6.757C11.7348 6.757 11.4804 6.86236 11.2929 7.04989C11.1054 7.23743 11 7.49178 11 7.757V11H7.757C7.49178 11 7.23743 11.1054 7.04989 11.2929C6.86236 11.4804 6.757 11.7348 6.757 12C6.757 12.2652 6.86236 12.5196 7.04989 12.7071C7.23743 12.8946 7.49178 13 7.757 13H11V16.243C11 16.5082 11.1054 16.7626 11.2929 16.9501C11.4804 17.1376 11.7348 17.243 12 17.243C12.2652 17.243 12.5196 17.1376 12.7071 16.9501C12.8946 16.7626 13 16.5082 13 16.243V13H16.243C16.5082 13 16.7626 12.8946 16.9501 12.7071C17.1376 12.5196 17.243 12.2652 17.243 12C17.243 11.7348 17.1376 11.4804 16.9501 11.2929C16.7626 11.1054 16.5082 11 16.243 11H13V7.757Z"
      fill="white"
    />
  </svg>
</template>
