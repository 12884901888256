<template>
  <footer>
    <h5>
      <p><a @click="goToLogIn">[A]</a></p>
    </h5>
  </footer>
</template>
<script>
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();

    const goToLogIn = () => {
      router.push({ name: "login" });
    };

    return {
      goToLogIn,
    };
  },
};
</script>
<style scoped>
footer {
  height: 50px;
  background-color: var(--primary-color);
}
</style>
