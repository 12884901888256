<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9533 6.20423C18.2066 6.5595 18.3333 6.73714 18.3333 7.00008C18.3333 7.26303 18.2066 7.44066 17.9533 7.79593C16.8149 9.39221 13.9076 12.8334 9.99996 12.8334C6.09228 12.8334 3.18504 9.39221 2.04666 7.79593C1.7933 7.44066 1.66663 7.26303 1.66663 7.00008C1.66663 6.73714 1.7933 6.5595 2.04666 6.20424C3.18504 4.60795 6.09228 1.16675 9.99996 1.16675C13.9076 1.16675 16.8149 4.60795 17.9533 6.20423Z"
      stroke="#14181F"
      stroke-width="1.5"
    />
    <path
      d="M12.5 7C12.5 5.61929 11.3807 4.5 10 4.5C8.61929 4.5 7.5 5.61929 7.5 7C7.5 8.38071 8.61929 9.5 10 9.5C11.3807 9.5 12.5 8.38071 12.5 7Z"
      stroke="#14181F"
      stroke-width="1.5"
    />
  </svg>
</template>
