<template>
  <div class="header-menu">
    <h4>{{ $route.meta.title }}</h4>
  </div>
</template>
<script>
export default {
  components: {},
  setup() {},
};
</script>
<style scoped>
.header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
}
</style>
