<template>
  <svg
    width="16"
    height="16"
    viewBox="0 1 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.728 3.23796C12.349 2.56515 12.6595 2.22875 12.9895 2.03252C13.7856 1.55905 14.7659 1.54432 15.5753 1.99368C15.9107 2.17991 16.2307 2.50685 16.8708 3.16072C17.5109 3.81458 17.8309 4.14152 18.0133 4.48419C18.4531 5.31101 18.4387 6.31241 17.9752 7.12567C17.7831 7.46271 17.4538 7.77989 16.7952 8.41426L8.95882 15.962C7.71069 17.1641 7.08663 17.7652 6.30668 18.0699C5.52673 18.3745 4.66929 18.3521 2.95443 18.3072L2.72111 18.3011C2.19905 18.2875 1.93802 18.2807 1.78628 18.1085C1.63454 17.9363 1.65526 17.6704 1.69669 17.1386L1.71919 16.8498C1.8358 15.353 1.8941 14.6047 2.18638 13.9319C2.47866 13.2592 2.98282 12.713 3.99115 11.6205L11.728 3.23796Z"
      stroke="#14181F"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M10.8333 3.3335L16.6666 9.16683"
      stroke="#14181F"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M11.6666 18.3335L18.3333 18.3335"
      stroke="#14181F"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
