<template>
  <div>
    <div></div>
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th>Member Name</th>
            <th>Transaction ID</th>
            <th>Date of Admission</th>
            <th>Provider Name</th>
            <th>Availment Type</th>
            <th>Reimbursable Amount</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in data" :key="item.id">
            <td>
              <div class="member-item">
                <div class="member-icon">
                  <img :src="require(`@/assets/Avatar.png`)" alt="icon" />
                </div>
                <div class="member-info">
                  <span class="member-name">{{ item.name }}</span>
                  <span class="member-comp">{{ item.comp }}</span>
                </div>
              </div>
            </td>
            <td>{{ item.transId }}</td>
            <td>{{ item.adDate }}</td>
            <td>{{ item.prov }}</td>
            <td>{{ item.availType }}</td>
            <td>{{ convertAmountFormat(item.amount) }}</td>
            <td>
              <span class="status-badge" :class="getStatusClass(item.status)">{{
                item.status
              }}</span>
            </td>
            <td>
              <div class="action-items">
                <div class="action-icon">
                  <IconEdit />
                </div>
                <div class="action-icon" @click="openModal(item)">
                  <IconView />
                </div>
                <div class="action-icon">
                  <IconReProcess />
                </div>
                <div class="action-icon"><IconOption /></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <global-modal
    :modelValue="actionButton"
    title="Encoding of Reimbursement"
    @modalHandler="getDataValue"
  >
    <template #body>
      <SectionApplication />
    </template>
  </global-modal>

  <global-modal
    :modelValue="isModalVisible"
    title="Reimbursement Status"
    @modalHandler="isModalVisible = val"
  >
    <template #body>
      <SectionApplicationViewing :itemData="passDataItem" />
    </template>
  </global-modal>
</template>

<script>
import SectionApplication from "@/components/partials/Account/Reimbursements/SectionApplication";
import IconView from "@/components/svg/icon-view.vue";
import IconEdit from "@/components/svg/icon-edit.vue";
import IconOption from "@/components/svg/icon-option.vue";
import IconReProcess from "@/components/svg/icon-reprocess.vue";
import UsedFormatUtils from "@/utils/format";
import SectionApplicationViewing from "@/components/partials/Account/Reimbursements/SectionApplicationViewing.vue";
import { ref } from "vue";
export default {
  components: {
    SectionApplication,
    IconView,
    IconEdit,
    IconOption,
    IconReProcess,
    SectionApplicationViewing,
  },
  props: {
    actionButton: Boolean,
  },
  setup(props, { emit }) {
    const { convertAmountFormat } = UsedFormatUtils();

    const isModalVisible = ref(false);

    const selectedItem = ref(null);
    const passDataItem = ref(null);

    const data = ref([
      {
        id: 1,
        name: "ECHAVARIA, MISTY",
        comp: "SPL. ACCT. 1",
        transId: "IWC-NRUTY19NC",
        adDate: "2024-10-01",
        prov: "UERM MEDICAL CENTER",
        availType: "OUT-PATIENT",
        amount: 1000.0,
        status: "Declined",
      },
      {
        id: 2,
        name: "SURIO, ALYSSA B.",
        comp: "ACHIEVERS SECURITY AGENCY PHILIPPINES",
        transId: "IWC-BKGIT92YD",
        adDate: "2024-10-01",
        prov: "ST. PATRICK HOSPITAL MEDICAL CENTER",
        availType: "OUT-PATIENT",
        amount: 1000.0,
        status: "Completed",
      },
      {
        id: 3,
        name: "ZURITA, KIMBERLY BINAY",
        comp: "ADD EVEN MANPOWER RESOURCES & SOLUTIONS, INC.",
        transId: "IWC-MVNE1108H",
        adDate: "2024-10-01",
        prov: "ST. THERESE DE LIMA MEDICAL HOSPITAL",
        availType: "OUT-PATIENT",
        amount: 1000.0,
        status: "Pending",
      },
    ]);

    const getStatusClass = (status) => {
      if (status === "Declined") {
        return "status-declined";
      } else if (status === "Completed") {
        return "status-completed";
      } else if (status === "Pending") {
        return "status-pending";
      } else {
        return;
      }
    };

    const getDataValue = (val) => {
      emit("actionHandler", val);
    };

    const viewItem = (id) => {
      selectedItem.value = data.value.find((item) => item.id === id);
      console.log(id);
    };

    const openModal = (val) => {
      isModalVisible.value = true;
      passDataItem.value = val;
    };

    return {
      getDataValue,
      data,
      getStatusClass,
      convertAmountFormat,
      viewItem,
      selectedItem,
      openModal,
      isModalVisible,
      passDataItem,
    };
  },
};
</script>

<style scoped>
.table-container {
  font-size: 12px;
  max-width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  border-left: none;
  border-right: none;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

.status-badge {
  display: inline-block;
  padding: 2px 10px;
  border-radius: 12px;
}
.status-declined {
  color: #c6432e;
  background-color: #ffc8c8;
}
.status-completed {
  color: #00a81c;
  background-color: #9effb3;
}
.status-pending {
  color: #db6e00;
  background-color: #ffed9e;
}

.member-item {
  display: flex;
  align-items: center;
  gap: 15px;
}

.member-icon {
  width: 20px;
  height: 20px;
  margin-right: 13px;
  padding-bottom: 13px;
}

.member-info {
  display: flex;
  flex-direction: column;
}

.member-name {
  font-weight: bold;
}

.member-comp {
  color: #666;
}

.action-items {
  display: flex;
  align-items: center;
  gap: 15px;
}

.action-icon {
  width: 17px;
  height: 17px;
  cursor: pointer;
}
</style>
