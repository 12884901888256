<template>
  <svg
    width="85"
    height="85"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.4666 51L41.7333 59.2667L62.4 38.6M81.3583 60.5667L85.0792 56.8458C85.8469 56.0782 86.4559 55.1668 86.8714 54.1638C87.287 53.1608 87.5008 52.0857 87.5008 51C87.5008 49.9143 87.287 48.8392 86.8714 47.8362C86.4559 46.8332 85.8469 45.9218 85.0792 45.1542L81.3583 41.4333C80.5907 40.6656 79.9818 39.7542 79.5665 38.7512C79.1511 37.7482 78.9374 36.6731 78.9375 35.5875V30.3333C78.9375 28.1409 78.0665 26.0382 76.5162 24.4879C74.9659 22.9376 72.8633 22.0667 70.6708 22.0667H65.4125C64.3268 22.067 63.2517 21.8534 62.2487 21.438C61.2456 21.0226 60.3342 20.4136 59.5666 19.6458L55.8458 15.925C55.0782 15.1572 54.1668 14.5482 53.1638 14.1327C52.1607 13.7172 51.0857 13.5033 50 13.5033C48.9143 13.5033 47.8392 13.7172 46.8362 14.1327C45.8332 14.5482 44.9218 15.1572 44.1541 15.925L40.4333 19.6458C39.6656 20.4134 38.7542 21.0223 37.7512 21.4377C36.7481 21.853 35.6731 22.0668 34.5875 22.0667H29.3333C28.2474 22.0661 27.172 22.2795 26.1685 22.6947C25.1651 23.1099 24.2533 23.7188 23.4852 24.4864C22.7171 25.2541 22.1078 26.1656 21.6921 27.1689C21.2764 28.1721 21.0625 29.2474 21.0625 30.3333V35.5917C21.0625 37.7875 20.1916 39.8875 18.6416 41.4375L14.9208 45.1583C14.1531 45.926 13.544 46.8373 13.1285 47.8404C12.713 48.8434 12.4991 49.9185 12.4991 51.0042C12.4991 52.0899 12.713 53.1649 13.1285 54.168C13.544 55.171 14.1531 56.0824 14.9208 56.85L18.6416 60.5708C20.1916 62.1208 21.0625 64.2208 21.0625 66.4167V71.675C21.0625 73.8675 21.9334 75.9701 23.4837 77.5204C25.034 79.0707 27.1367 79.9417 29.3291 79.9417H34.5875C36.7833 79.9417 38.8833 80.8125 40.4333 82.3625L44.1541 86.0833C44.9218 86.8511 45.8332 87.4601 46.8362 87.8756C47.8392 88.2911 48.9143 88.505 50 88.505C51.0857 88.505 52.1607 88.2911 53.1638 87.8756C54.1668 87.4601 55.0782 86.8511 55.8458 86.0833L59.5666 82.3625C61.1174 80.8126 63.22 79.9419 65.4125 79.9417H70.6708C72.8633 79.9417 74.9659 79.0707 76.5162 77.5204C78.0665 75.9701 78.9375 73.8675 78.9375 71.675V66.4167C78.9375 64.2208 79.8083 62.1167 81.3583 60.5667Z"
      stroke="#A6A6A6"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
